import Api from "@/api/Api";

export default {
  Get() {
    return Api().get("/voucher");
  },
  //   getDetail(id) {
  //     return Api().get(`/user/${id}`);
  //   },
  //   GetOptionUserRole(role) {
  //     return Api().get(`/user/getuser?role=${role}`);
  //   },
  Update(id, data) {
    return Api().put(`/voucher/${id}`, data);
  },
  Add(data) {
    return Api().post(`/voucher/`, data);
  },

  Delete(id) {
    return Api().delete(`/voucher/${id}`);
  },
  DeleteMultiple(data) {
    return Api().post(`/voucher/all`, data);
  },
  //   UploadImage(id, data) {
  //     return Api().post(`user/upload/${id}`, data);
  //   },
};

